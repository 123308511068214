import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { Head } from '../components/templates/head';
import { NextStepHeader } from '../components/organisms/next-step-header';
import { Button } from '../components/atoms/button';
import { PhoneNumber } from '../components/atoms/phone-number';

import LeftArrowIcon from '../assets/left-arrow.svg';

// markup
const UrgentSupport = () => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	const handleBackTracking = () => {
		const hasGtag = typeof window.gtag === 'function';

		if (!hasGtag) return;

		window.gtag('event', 'click', {
			click_text: 'Back',
			click_type: 'button',
		});
	};

	return (
		<>
			<Head title={site.siteMetadata.title} />
			<main id="main" className="container mx-auto bg-brand-pale-300">
				<NextStepHeader
					title="Call us now"
					subtitle="Get urgent support to keep your business on the right side of the law."
					backBtn={
						<Button
							variant="White"
							onClick={() => {
								handleBackTracking();
								navigate('/');
							}}
							className="flex items-center !py-xxs-f !px-l-f"
						>
							<LeftArrowIcon className="mr-2 max-w-none" />
							Back
						</Button>
					}
				/>
				<div className="px-4">
					<div className="mx-auto wrapper-small">
						<PhoneNumber />
					</div>
				</div>
			</main>
		</>
	);
};

export default UrgentSupport;
