import React from 'react';
import PropTypes from 'prop-types';

import { Header } from '../../molecules/header';

export const NextStepHeader = ({ title, subtitle, backBtn, restartBtn }) => (
	<section className="px-4 mt-7">
		<div className="mx-auto wrapper-small">
			<Header backBtn={backBtn} restartBtn={restartBtn} />
			<hgroup className="my-10">
				<h1 className="mb-2 leading-tight text-3xl-f">{title}</h1>
				{subtitle ? <h2 className="text-lg-f">{subtitle}</h2> : null}
			</hgroup>
		</div>
	</section>
);

NextStepHeader.defaultProps = {
	subtitle: '',
	backBtn: <></>,
	restartBtn: <></>,
};

NextStepHeader.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	backBtn: PropTypes.element,
	restartBtn: PropTypes.element,
};
